.marker {
    width:0;
    height:0;
  }
  
  .marker  span {
    display:flex;
    justify-content:center;
    align-items:center;
    box-sizing:border-box;
    width: 30px;
    height: 30px;
    color:#fff;
    background: #4D2D73;
    border:solid 2px;
    border-radius: 0 70% 70%;
    box-shadow:0 0 2px #000;
    cursor: pointer;
    transform-origin:0 0;
    transform: rotateZ(-135deg);
  }
  
  .marker b {
    transform: rotateZ(135deg)
  }
  
  .company-marker span {
    background: #000000;
  }

  .marker span .marker-distance {
    border-radius: 6px;
    font-size: 16px;
    font-weight: 800;
    margin-right: -2px;
    margin-top: -38px;
    transform: rotate(135deg);
  }

  .marker span .marker-conventionne {
    border-radius: 6px;
    font-size: 13px;
    font-weight: 800;
    margin-top: 11px;
    margin-right: 29px;
    position: absolute;
    transform: rotate(135deg);
    border-radius: 13px;
    padding: 1px;
  }

  .company-naf-marker span {
    background: #08d54c;
  }

  .company-pentair-marker span {
    background: rgb(100,167,11);
    background: linear-gradient(90deg, rgba(100,167,11,1) 0%, rgba(9,36,79,1) 100%);
  }

  .company-sosbricolage-marker span {
    background: #fe6502;
  }

  .company-odoo-marker span {
    background: #0696f0;
  }

  .company-colors-marker span {
    background: #0c2092;
  }

  .selected-marker span {
    background: #ff00a7;
  }

  .disabled-marker span {
    background: #6f6b75;
  }

  .marker-client {
    width: 48px;
    height: 48px;
  }

  .marker-client > p {
    font-weight: 900;
    font-size: 13px;
    background-color: #fff;
    border-radius: 12px;
  }

  .marker-formation {
    height: 19px;
    margin-right: 7px;
    margin-top: -30px;
    width: 19px;
    transform: rotate(-44deg);
    background: radial-gradient(circle at 3.1% 8.2%, rgb(248, 250, 107) 0%, rgb(238, 148, 148) 98.2%);
    border-radius: 10px;
  }