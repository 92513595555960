.maplibregl-ctrl-geocoder {
    text-align: left;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl, .maplibregl-ctrl-top-right .maplibregl-ctrl {
    width: 350px;
}

.css-o0y08g {
    min-width: 280px;
}